<!-- product -->
<template>
    <div class="product" id="product">
        <header class="header">
            <div class="header-info">
                <h2>智慧作业平台</h2>
                <p>
                    内嵌安防、环卫、工程、质控等各条线标准化工作流程与精细化管理方案，实现人车物事精准数据管控，全面提升数智化管理效率。
                </p>
                <div class="info-btn">
									  <span><a href="#applyContact">预约试用</a></span>
                    <span @click="qrCode">联系我们</span>
                </div>
            </div>
            <div class="header-img">
                <img v-lazy="require('../../assets/product/banner1-pc.png')" alt="" />
            </div>
        </header>
        <!-- 功能跳转开始 -->
        <div class="feature-list">
            <ul>
                <li v-for="(item, index) in featureList" :key="index" @click="featureListClick(index, item.id)">
                    <a :href="item.id" :class="item.isActive ? 'isActive' : ''" :ref="item.id">{{ item.name }}</a>
                </li>
            </ul>
        </div>
        <!-- 功能跳转结束 -->
        <!-- 功能区内容开始 -->
        <div class="list-content">
					<section class="listCore">
						<a name="listCore" style="position: relative; top: -4.5rem"></a>
						<h2>核心优势</h2>
						<div class="listCore-box">
							<div class="listCore-content" v-for="(item, index) in listCoreContentList" :key="index">
									<div class="info">
											<img v-lazy="item.icon" alt="" />
											<h4>{{ item.h4 }}</h4>
											<p>{{ item.details }}</p>
									</div>
							</div>
						</div>
					</section>
					<section class="listLight">
					    <a name="listLight" style="position: relative; top: -4.5rem"></a>
					    <h2>亮点功能</h2>
					    <div class="listLight-content" v-for="(item, index) in listLightContentList" :key="index">
					        <img v-lazy="item.img" alt="" />
					        <div class="info">
					            <h4>{{ item.h4 }}</h4>
					            <p v-for="(cItem, cIndex) in item.detailsList">
											<img v-lazy="cItem.icon" alt="" />
											{{ cItem.title }}
											</p>
					        </div>
					    </div>
					</section>
					<section class="listFunc">
						<div class="more" id="more">
								<div class="info">
										<h4>如需了解更多功能，请预约线下现场演示或直接联系我们</h4>
										<p>可根据具体场景，具体需求个性化定制功能</p>
								</div>
								<div class="info-btn">
										<span><a style="color: #1976e1" href="#applyContact">预约试用</a></span>
										<span @click="qrCode()">联系我们</span>
								</div>
						</div>
						
						<a name="listFunc" style="position: relative; top: -4.5rem"></a>
						<h2>智能协作化的作业平台</h2>
						<div class="listFunc-box">
							<div class="listFunc-content" v-for="(item, index) in listFuncContentList" :key="index">
								<img v-lazy="item.icon" alt="" />
								<div class="title">
									<h4>{{ item.h4 }}</h4>
								<p>{{ item.details }}</p>
								</div>
							</div>
						</div>
					</section>
					<section class="listProduct">
							<a name="listProduct" style="position: relative; top: -4.5rem"></a>
							<div class="tab-box">
								<ul>
									<!-- <li class="tab-role selected">角色</li>
									<li class="tab-role">场景</li> -->
									<li :class="tabRoleIndex===index?'tab-role selected':'tab-role'" v-for="(item,index) in tabRoleList" :key="index" @click="onChangeRole(index)">{{item.name}}</li>
								</ul>
							</div>
							<div class="content-box">
								<div class="role-box" v-if="tabRoleIndex===0">
									<div class="role_user" v-for="(item, index) in listFuncRoleList" :key="index">
										<img v-lazy="item.icon" alt="" />
										<p>{{ item.title }}</p>
									</div>
								</div>
								<div class="role-box hide" v-if="tabRoleIndex===1">
									<div class="role_user" v-for="(item, index) in listFuncSceneList" :key="index">
										<img v-lazy="item.icon" alt="" />
										<p>{{ item.title }}</p>
									</div>
								</div>
							</div>
					</section>
            <section class="listCase">
                <a name="listCase" style="position: relative; top: -4.5rem"></a>
                <h2>相关案例</h2>
                <div class="carousel">
									<el-carousel arrow="never">
										<el-carousel-item class="el-carousel-item" v-for="(item, index) in listCaseList" :key="index">
											<router-link v-for="(item1, index1) in item.children" :key="index1" :to="item1.path">
												<div class="content">
													<h4>{{ item1.title }}</h4>
													<p>{{ item1.details }}</p>
													<div class="info">
														<div
															class="details-numbers"
															v-for="(item2, index2) in item1.children"
															:key="index2"
														>
															<h5>{{ item2.numbers }}</h5>
															<h5>{{ item2.name }}</h5>
														</div>
													</div>
												</div>
											</router-link>
										</el-carousel-item>
									</el-carousel>
                </div>
            </section>
            <section class="listDownload">
                <a name="listDownload" style="position: relative; top: -4.5rem"></a>
                <h2>产品下载</h2>
                <div class="content">
                    <div class="list" v-for="(item, index) in listDownloadList" :key="index">
                        <img v-lazy="item.img" alt="" />
                        <div class="info">
                            <h4><img v-lazy="item.icon" alt="" />{{ item.title }}</h4>
                            <p>{{ item.details }}</p>
                        </div>
                    </div>
                    <div class="mobile-list">
                        <img src="../../assets/product/a@3x.png" alt="" />
                        <div class="info">
                            <h4><img src="../../assets/product/40@3x.png" alt="" />安卓手机端</h4>
                            <p class="btn" @click="downLoad(0)">立即下载</p>
                        </div>
                    </div>
                    <div class="mobile-list">
                        <img src="../../assets/product/iOS@3x.png" alt="" />
                        <div class="info">
                            <h4><img src="../../assets/product/ios  40@3x.png" alt="" />iOS小程序</h4>
                            <p class="ios">iOS用户可截图二维码，微信扫码直接访问小程序</p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="listProblem">
                <a name="listProblem" style="position: relative; top: -4.5rem"></a>
                <h2>常见问题</h2>
                <div class="content">
                    <ul>
                        <li v-for="(item, index) in listProblemList" :key="index" @click="listProblemListClick(index)">
                            <div class="main" :class="item.isOpen ? 'bg' : 'main'">
                                <img v-lazy="item.img" class="No" />
                                <h4>{{ item.title }}</h4>
                                <img
                                    class="more"
                                    src="../../assets/product/opne@3x.png"
                                    alt=""
                                    :class="item.isOpen ? 'rotate' : 'more'"
                                />
                            </div>
                            <div class="details" v-if="item.isOpen">
                                <p>{{ item.details }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
								<div class="tips"></div>
                <!--div class="tips">更多问题请移步<span>帮助中心</span>进行查看</div-->
            </section>
        </div>
        <!-- 功能区内容结束 -->
        <!-- 二维码 -->

        <contactUs ref="contactUs"></contactUs>
				<apply ref="apply"></apply>
    </div>
</template>

<script>
import apply from "@/components/apply/apply";
import contactUs from "@/components/contactUs/contactUs";
import { myMixins } from "@/utils/mixin/mixin.js";
export default {
    mixins: [myMixins],
    components: { apply, contactUs },
    data() {
        return {
            isqrCode: false, //默认不显示二维码
            isOpenMobile: false,
            screenWidth: Number,
						tabRoleList:[{
							name:'角色',
							checked:false
						},{
							name:'场景',
							checked:false
						}],
						tabRoleIndex:0,
            featureList: [
							  { name: "核心优势", isActive: true, id: "#listCore" },
                { name: "亮点功能", isActive: false, id: "#listLight" },
                { name: "产品特色", isActive: false, id: "#listFunc" },
                { name: "角色场景", isActive: false, id: "#listProduct" },
                { name: "相关案例", isActive: false, id: "#listCase" },
                { name: "产品下载", isActive: false, id: "#listDownload" },
                { name: "常见问题", isActive: false, id: "#listProblem" },
                { name: "预约试用", isActive: false, id: "#applyContact" },
            ],
						//核心优势
						listCoreContentList: [
							{
							    isOpen: false,
							    icon: require("../../assets/product/p1-core1.png"),
							    h4: "标准化管理体系",
							    details:
							        "内嵌标准化业务指标管理、标准化业务管理、标准化工作流程，统一数字底座实现跨平台、跨项目数据灵活调用一屏统管，保障实效赋能决策。",
							},
							{
							    isOpen: false,
							    icon: require("../../assets/product/p1-core2.png"),
							    h4: "多元化场景适配",
							    details:
							        "全面覆盖城市服务、公共服务、园区服务等新型“大物业模式”全域化项目场景，打造标准化、精细化、数字化创新物业项目管理标准。",
							},
							{
							    isOpen: false,
							    icon: require("../../assets/product/p1-core3.png"),
							    h4: "模块化功能配置",
							    details:
							        "根据实际业务框架提炼模块化功能组件，降低基层人员学习门槛，减轻数字化转型负担，满足多样化工作流程需求，提升管理效率、管理精度。",
							},
						],
						//亮点功能
						listLightContentList: [
							{
								isOpen: false,
								img: require("../../assets/product/P1-Light1.png"),
								h4: "综合事件快捷上报，及时处置",
								detailsList:[
									{
										icon: require("../../assets/product/zz1.png"),
										title: '随时随地一键上报，一键指派'
									},{
										icon: require("../../assets/product/zz2.png"),
										title: '自动定位报事地点，方便跟踪管理'
									},{
										icon: require("../../assets/product/zz3.png"),
										title: '实时消息通知，事件状态及时跟进'
									}
								]
							},{
								isOpen: false,
								img: require("../../assets/product/P1-Light2.png"),
								h4: "维修保养及时跟进，提升效率",
								detailsList:[
									{
										icon: require("../../assets/product/wb1.png"),
										title: '统一设备资产管理，一键关联报修与追溯'
									},{
										icon: require("../../assets/product/wb2.png"),
										title: '支持多种内外部协同工作流模式'
									},{
										icon: require("../../assets/product/wb3.png"),
										title: '自动化维保管理，免去手工台账记忆考验'
									}
								]
							},{
								isOpen: false,
								img: require("../../assets/product/P1-Light3.png"),
								h4: "自主创建计划任务，智能高效",
								detailsList:[
									{
										icon: require("../../assets/product/jh1.png"),
										title: '全面掌握实时巡检数据，开启智慧巡检'
									},{
										icon: require("../../assets/product/jh2.png"),
										title: '配置不同任务要点，精准高效巡检管理'
									},{
										icon: require("../../assets/product/jh3.png"),
										title: '不同设备类型，制定不同周期的巡检计划'
									}
								]
							},{
								isOpen: false,
								img: require("../../assets/product/P1-Light4.png"),
								h4: "人员车辆实时定位，精准追踪",
								detailsList:[
									{
										icon: require("../../assets/product/dw1.png"),
										title: '实时定位追踪，告警状态实时反馈'
									},{
										icon: require("../../assets/product/dw2.png"),
										title: '历史轨迹，综合研判分析用户行为'
									},{
										icon: require("../../assets/product/dw3.png"),
										title: '电子围栏，实现高效调度、安全监控'
									}
								]
							},{
								isOpen: false,
								img: require("../../assets/product/P1-Light5.png"),
								h4: "云端库存查询管理，方便快捷",
								detailsList:[
									{
										icon: require("../../assets/product/ck1.png"),
										title: '备件库存一键查询，方便及时更新库存'
									},{
										icon: require("../../assets/product/ck2.png"),
										title: '以列表形式记录出入库，多条件快速检索'
									},{
										icon: require("../../assets/product/ck3.png"),
										title: '记录产品编码、名称等相关数据属性'
									}
								]
							}
						], //功能列表跳转
            listFuncContentList: [
							{
									isOpen: false,
									icon: require("../../assets/product/P1-t1.png"),
									h4: "更灵活",
									details: "工作台提供多种功能，根据校色类型自定义配置",
							},
							{
									isOpen: false,
									icon: require("../../assets/product/P1-t2.png"),
									h4: "更智能",
									details: "通过多个系统集成风险预警，通过流程再造固化作业规则",
							},
							{
									isOpen: false,
									icon: require("../../assets/product/P1-t3.png"),
									h4: "更规范",
									details: "每项业务自主设定指标阈值，实现标准化作业体系落地",
							},
							{
									isOpen: false,
									icon: require("../../assets/product/P1-t4.png"),
									h4: "更高效",
									details: "事件节点全流程通知提醒，随时随地完成任务处置",
							},
					], //产品特色
					listFuncRoleList:[
						{
							icon: require("../../assets/product/P1-dp1.png"),
							title: '政府协管部门',
						},
						{
							icon: require("../../assets/product/P1-dp2.png"),
							title: '运营管理方',
						},
						{
							icon: require("../../assets/product/P1-dp3.png"),
							title: '品检条线',
						},
						{
							icon: require("../../assets/product/P1-dp4.png"),
							title: '环卫条线',
						},
						{
							icon: require("../../assets/product/P1-dp5.png"),
							title: '工程条线',
						},
						{
							icon: require("../../assets/product/P1-dp6.png"),
							title: '安防条线',
						}
					],// 角色岗位
					listFuncSceneList:[
						{
							icon: require("../../assets/product/P1-cj1.png"),
							title: '城市服务',
						},
						{
							icon: require("../../assets/product/P1-cj2.png"),
							title: '智慧社区',
						},
						{
							icon: require("../../assets/product/P1-cj3.png"),
							title: '环卫项目',
						},
						{
							icon: require("../../assets/product/P1-cj4.png"),
							title: '园区项目',
						},
						{
							icon: require("../../assets/product/P1-cj5.png"),
							title: '后勤保障',
						},
						{
							icon: require("../../assets/product/P1-cj6.png"),
							title: '医院物业',
						}
					],// 场景列表
					scrollTop: Number,
        };
    },
    directives: {
        fixed: {
            // inserted 被绑定元素插入父节点时调用
            inserted() {
                this.scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
                document.body.style.cssText += "position:fixed;width:100%;top:-" + scrollTop + "px;";
            },
            // unbind 指令与元素解绑时调用
            unbind() {
                let body = document.body;
                body.style.position = "";
                let top = body.style.top;
                document.body.scrollTop = document.documentElement.scrollTop = -parseInt(top);
                body.style.top = "";
            },
        },
    },
    computed: {},
    watch: {},
    created() {
        this.screenWidth = window.document.body.clientWidth;
    },
    mounted() {},
    methods: {
        // 功能列表跳转
        featureListClick(e, id) {
            this.featureList.map((item, index) => {
                item.isActive = e === index ? true : false;
            });
            let section = window.document.getElementById(id.slice(1));
            // console.log(section);
            // document.body.scrollTop = -100;
            // document.documentElement.scrollTop = -100;
            // section.scrollTop = 1000;
            // document.body.scrollTop = 1000;
            // document.body.style.cssText = "padding-top:" + 292 + "px;";
            // console.log(section.style, id, " section.scrollTop");
            // console.log(document.body.scrollTop, document.documentElement.scrollTop, "document.body.scrollTop");
            // document.documentElement.scrollTop += 160;
        },
        // 常见问题展开点击
        listProblemListClick(e) {
            this.listProblemList.map((item, index) => {
                item.isOpen = e === index ? !item.isOpen : false;
            });
        },
        // 跳转联系
        onTopClick(e) {},
				//角色场景tab切换
				onChangeRole(index){
					this.tabRoleIndex = index
				}
    },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 751px) {
    @import "product_PC.scss";
}
@media screen and (max-width: 750px) {
    @import "product_mobile.scss";
}
</style>
