<!-- 预约试用 -->
<template>
    <div class="information-submitted" id="applyContact">
        <div class="title">
            <h2>填写申请信息</h2>
            <p>请填写以下信息，我们会尽快安排专业人员与您沟通，帮助您快速建立企业数据解决方案。</p>
        </div>
        <div class="content">
            <div class="content-input">
                <input type="text" placeholder="姓名：" v-model="submitMsg.userName" />
                <input type="number" placeholder="联系方式：" v-model="submitMsg.mobilePhone" />
            </div>
            <div class="content-textarea">
                <textarea placeholder="留言给我们" v-model="submitMsg.remark"></textarea>
            </div>
        </div>
        <div class="submit" @click="submit()">预约试用</div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            submitMsg: {
							userName: '',
							mobilePhone: '',
							remark: ''
						}, //信息提交
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 预约试用提交信息
        submit() {
            let reg_user = /^[\u4e00-\u9fa5]{2,4}$/; //2-4个中文字符正则
            let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //11位手机号码正则
            if (!reg_user.test(this.submitMsg.userName)) {
                this.$message({
                    message: "请输入正确的姓名！",
                    type: "error",
                });
                return;
            } else if (!reg_tel.test(this.submitMsg.mobilePhone)) {
                this.$message({
                    message: "请输入正确的11位电话号码！",
                    type: "error",
                });
                return;
            }
            if (this.submitMsg.remark.length == 0) {
                this.$message({
                    message: "请输入留言内容",
                    type: "error",
                });
                return;
            }
            this.$post("officewebUser/save", this.submitMsg)
                .then((res) => {
                    if (res.data.code == 0) {
                        this.$message({
                            message: "提交成功！",
                            type: "success",
                        });
                        this.submitMsg = {};
                    } else {
                        this.$message({
                            message: "提交失败！请稍后再试",
                            type: "error",
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.$message({
                        message: "提交失败！请稍后再试",
                        type: "error",
                    });
                });
        },
    },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 751px) {
    .information-submitted {
        padding: 0 13rem;
        box-sizing: border-box;
        overflow: hidden;
        background: url("../../assets/product/product-bg@3x.jpg") no-repeat center center/100% 100%;

        .title {
            margin-top: 5rem;

            h2 {
                font-size: 1.6rem;
                color: #ffffff;
                line-height: 1.6rem;
                font-weight: 500;
                text-align: center;
            }

            p {
                text-align: center;
                margin-top: 1.3rem;
                font-size: 1rem;
                color: rgba(#ffffff, 0.5);
                line-height: 1rem;
                font-weight: 500;
            }
        }

        .content {
            display: flex;
            margin-top: 3rem;

            // align-items: center;
            .content-input {
                flex: 1;

                input {
                    border: none;
                    outline: none;
                    display: block;
                    height: 2.4rem;
                    background: rgba(255, 255, 255, 0.12);
                    border: 0.1rem solid rgba(#ffffff, 0.42);
                    border-radius: 0.15rem;
                    font-size: 1rem;
                    color: #ffffff;
                    line-height: 2.4rem;
                    width: 100%;
                    margin-top: 1.5rem;
                    padding-left: 1rem;
                    box-sizing: border-box;

                    &:nth-child(1) {
                        margin-top: 0;
                    }
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }

                input[type="number"] {
                    -moz-appearance: textfield;
                }

                ::-webkit-input-placeholder {
                    font-size: 1rem;
                    color: rgba(#ffffff, 0.4);
                }
            }

            .content-textarea {
                margin-left: 1.5rem;
                flex: 1;

                textarea {
                    padding-left: 1rem;
                    padding-top: 0.7rem;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    border: none;
                    outline: none;
                    background: rgba(255, 255, 255, 0.12);
                    border: 0.1rem solid rgba(#ffffff, 0.42);
                    color: #ffffff;
                    border-radius: 0.15rem;
                    max-height: 6.3rem;
                    max-width: 34.25rem;
                    min-height: 6.3rem;
                    min-width: 34.25rem;
                    font-size: 1rem;
                }

                ::-webkit-input-placeholder {
                    font-size: 1rem;
                    color: rgba(#ffffff, 0.4);
                }
            }
        }

        .submit {
            width: 9.2rem;
            height: 2.4rem;
            background: #ffffff;
            border: 1px solid #fbfeff;
            border-radius: 0.15rem;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;
            margin-bottom: 5rem;
            cursor: pointer;
            font-size: 1rem;
        }
    }
}
@media screen and (max-width: 750px) {
    .information-submitted {
        padding: 0 3rem;
        box-sizing: border-box;
        background: url("../../assets/solutions/bg11.png") no-repeat center center/100% 100%;
        overflow: hidden;

        .title {
            text-align: center;
            margin-top: 5rem;

            h2 {
                font-size: 2.1rem;
                color: #ffffff;
                line-height: 2.1rem;
                font-weight: 500;
            }

            p {
                margin-top: 1.2rem;
                font-size: 1.2rem;
                color: #ffffff;
                line-height: 1.8rem;
                font-weight: 500;
            }
        }

        .content {
            .content-input {
                input {
                    height: 3.6rem;
                    line-height: 2.4rem;
                    border: none;
                    outline: none;
                    display: block;
                    background: rgba(255, 255, 255, 0.12);
                    border: 0.1rem solid rgba(#ffffff, 0.42);
                    border-radius: 0.15rem;
                    font-size: 1.4rem;
                    color: #ffffff;
                    width: 100%;
                    margin-top: 1.5rem;
                    padding-left: 1rem;
                    box-sizing: border-box;

                    &:nth-child(1) {
                        margin-top: 3rem;
                    }
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }

                input[type="number"] {
                    -moz-appearance: textfield;
                }

                ::-webkit-input-placeholder {
                    font-size: 1rem;
                    color: rgba(#ffffff, 0.4);
                }
            }

            .content-textarea {
                margin-top: 1.6rem;

                textarea {
                    padding-left: 1rem;
                    padding-top: 0.7rem;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    border: none;
                    outline: none;
                    background: rgba(255, 255, 255, 0.12);
                    border: 0.1rem solid rgba(#ffffff, 0.42);
                    color: #ffffff;
                    border-radius: 0.15rem;
                    height: 6.3rem;
                    font-size: 1rem;
                }

                ::-webkit-input-placeholder {
                    font-size: 1rem;
                    color: rgba(#ffffff, 0.4);
                }
            }
        }

        .submit {
            width: 100%;
            height: 3.6rem;
            background: #ffffff;
            border: 1px solid #fbfeff;
            border-radius: 0.15rem;
            margin-top: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 6rem;
            cursor: pointer;
            font-size: 1.4rem;
        }
    }
}
</style>
